<template>
	<div class="counter d-flex justify-space-between align-center">
		<button class="color-golden h3 minus" :class="{disabled:counter<=1}" @click="sub">-</button>
		<v-text-field
				v-model="counter"
				dense hide-details flat
				maxlength="2"
				@focus="onFocus"
				@keydown="filterAmountKey"
				@keyup="fixAmount"
		/>
		<button class="color-golden h3 plus" @click="add">+</button>
	</div>
</template>

<script>
	export default {
		name: "counter",
		props: ["value"],
		data: () => {
			return {
				counter: 0,
			}
		},
		watch: {
			value() {
				this.counter = this.value;
			}
		},
		methods: {
			filterAmountKey(e) {
				//console.log(e);
				const a = e.key;

				// ignore arrows, del, backspace
				if (
					e.keyCode === 37 ||
					e.keyCode === 39 ||
					e.keyCode === 8 ||
					e.keyCode === 46 ||
					e.keyCode === 35 ||
					e.keyCode === 36 ||
					e.keyCode === 9 ||
					(e.key === "x" && e.ctrlKey) ||
					(e.key === "c" && e.ctrlKey) ||
					(e.key === "v" && e.ctrlKey)
				)
					return true;
			},
			fixAmount(e) {
				if (
					e &&
					(e.keyCode === 37 ||
						e.keyCode === 39 ||
						e.keyCode === 8 ||
						e.keyCode === 46 ||
						e.keyCode === 35 ||
						e.keyCode === 36)
				)
					return true;

				let s = this.counter + "";
				s = s.replace(/\D+/g, "");
				//console.log("s=" + s);
				//v = v.substr(1);
				s = Number(s);
				if (isNaN(s)) s = 0;
				else if (s < 0) s = 0;

				//this.isFormValid = s > 0;
				this.counter = s;
				this.$emit("input", this.counter);
				this.$emit("change", this.counter);
			},
			onFocus(event) {
				//this.validateDebounced(item, section);
				event.target.select();
			},
			add() {
				this.counter++;
				this.$emit("input", this.counter);
				this.$emit("change", this.counter);
			},
			sub() {
				if (this.counter <= 1) return 1;
				this.counter--;
				this.$emit("input", this.counter);
				this.$emit("change", this.counter);
			}
		},
		created() {
			this.counter = this.value;
		}
	}
</script>

<style lang="scss">
	.counter {
		width: 100%;
		max-width: 90px;
		height: 41px;
		border: 2px solid $black-50;

		.v-text-field {
			width: 30px;
			margin: 0 5px 0 0;

			input {
				padding: 0 !important;
				width: 100%;
				font-size: 16px;
				text-align: center;
			}

			.v-input__slot:before, .v-input__slot:after {
				border: none !important;
			}

			/*fieldset {
				//height: 32px;
				top: 0;
			}*/

			legend {
				display: none;
			}
		}

		button {
			width: 24px;
			height: 28px;
			line-height: 28px;
			padding: 0;
			@include transition();

			&.minus {
				margin-left: 5px;
			}

			&.disabled {
				opacity: 0.2;
				cursor: default;
			}

			&:not(.disabled):hover {
				color: $black !important;
			}
		}
	}

</style>